import Vue from 'vue';

Vue.filter('odometerFormat', (value: number) => {
    if (!value) {
        return '';
    }
    return new Intl.NumberFormat().format(value);
});




