import Vue from 'vue';
import Axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import Router from 'vue-router';

class Http extends Vue {
    public router!: Router;
    public baseURL = '/api/lk';
    private axios!: AxiosInstance;

    constructor() {
        super();

        this.baseURL = '/api/lk';

        if (document.location.hostname === 'localhost') {
            this.baseURL = 'https://lk.transpropusk.ru/api/lk';
            // this.baseURL = 'https://lk.propusk-expert.ru/api/lk';
        }
        this.axios = Axios.create({
            baseURL: this.baseURL,
            timeout: 60 * 3 * 1000
        });
    }

    public onSystemError(callback: (error: string) => void) {
        this.$on('onSystemError', callback);
    }

    public systemError(error: string) {
        this.$emit('onSystemError', error);
    }

    public async get(url: string, config?: AxiosRequestConfig): Promise<IRequest> {
        return new Promise<IRequest>((resolve, reject) => {
            this.request(resolve, reject, url, {}, config);
        });
    }

    public async post(url: string, data?: any, config?: AxiosRequestConfig): Promise<IRequest> {
        return new Promise<IRequest>((resolve, reject) => {
            this.request(resolve, reject, url, data, config);
        });
    }

    public setRouter(value: Router) {
        this.router = value;
    }

    public assignToken(post: any) {
        const p = Object.assign({}, post);
        if (localStorage.getItem('token') !== null) {
            p.token = localStorage.getItem('token');
        }
        return p;
    }


    private request(resolve: (value?: (PromiseLike<IRequest> | IRequest)) => void,
                    reject: (error?: any) => void,
                    url: string, post: any, config: AxiosRequestConfig | undefined) {

        this.axios.post(url, this.assignToken(post), config)
            .then((req) => {
                const data = req.data as IRequest;
                if (typeof data !== 'object') {
                    this.systemError('Произошла ошибка при выполнения запроса. Попробуйте ещё раз.');
                    reject(new HttpError({error: 'parsing_error'}));
                    return;
                } else if (!data.error) {
                    resolve(data.results);
                } else {
                    switch (data.error) {

                        case 'token_invalid':
                        case 'auth_invalid':
                            document.location.href = '/login/in';
                            break;
                        case 'parameters_invalid':
                            reject(new HttpError({error: data.error, errors: data.errors}));
                            break;
                        default:
                            reject(new HttpError({error: data.error}));
                            break;
                    }
                }
            })
            .catch((error) => {

                if (error.toString() === 'Error: Network Error') {
                    this.systemError('Проверьте покдлчение к интернету и повторите запрос.');
                    return;
                }
                this.systemError(error.toString());
            });

    }

}

export default new Http();

export interface IRequest {
    ok: boolean;
    error: string;
    results: any;
    runtime: string;
    errors?: IRequestError[];
}

export interface IRequestError {
    message: string;
    field: string;
}


export class HttpError {
    error: string = '';
    errors: IRequestError[] = [];

    public constructor(init?: Partial<HttpError>) {
        Object.assign(this, init);
    }
}
