import Vue from 'vue';
import TransportFilter from '@/components/transports/TransportFilter';
import UserManager from '@/models/UserManager';

class AppStore extends Vue {

    containerWidth = '';
    displayMode = '';
    clientName = '';
    regNumbers: string[] = [];
    isFinesEnabled = 0;
    isRnisEmulationEnabled = 0;

    transportFilter = new TransportFilter();

    manager = new UserManager();
    assistant = new UserManager();

    transportUpdateFilter(filter: TransportFilter) {
        this.$emit('onTransportUpdateFilter', filter);
    }

    onTransportUpdateFilter(callback: (f: TransportFilter) => void) {
        this.$on('onTransportUpdateFilter', callback);
    }

    snackbarOpenSuccess(message: string) {
        this.$emit('onSnackbarOpenSuccess', message);
    }

    onSnackbarOpenSuccess(callback: (value: string) => void) {
        this.$on('onSnackbarOpenSuccess', callback);
    }

    showLoader() {
        this.$emit('onShowLoader', true);
    }

    hideLoader() {
        this.$emit('onShowLoader', false);
    }

    onDisplayMode(callback: (value: string) => void) {
        this.$on('onDisplayMode', callback);
    }

    onContainerWidth(callback: (value: string) => void) {
        this.$on('onContainerWidth', callback);
    }

    setDisplayModeTable() {
        this.setDisplayMode(('table'));
    }

    setDisplayModeBlock() {
        this.setDisplayMode(('block'));
    }

    setDisplayMode(mode: string) {
        this.displayMode = mode;
        this.containerWidth = mode === 'table' ? '1500px' : '800px';

        this.$emit('onContainerWidth', this.containerWidth);
        this.$emit('onDisplayMode', mode);

        localStorage.setItem('displayMode', mode);

    }

    onShowLoader(callback: (value: boolean) => void) {
        this.$on('onShowLoader', callback);
    }

    off() {
        this.$off();
    }

}


export default new AppStore();
