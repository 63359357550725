import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [

        {
            path: '/',
            name: 'home',
            component: () => import('@/components/transports/TransportView.vue'),
        },
        {
            path: '/dcs',
            component: () => import('@/components/dcs/DcsView.vue'),
        },
        {
            path: '/fines',
            component: () => import('@/components/fines/FinesView.vue'),
        },
        {
            path: '/transports',
            component: () => import('@/components/transports/TransportView.vue'),
        },
        {
            path: '/login/auto',
            component: () => import('@/components/login/Auto.vue'),
        },
        {
            path: '/login/in',
            component: () => import('@/components/login/In.vue'),
        },
        {
            path: '/setting',
            component: () => import('@/components/setting/SettingView.vue'),
        },
        {
            path: '/rnis/emulation',
            component: () => import('@/components/rnis/emulation/RnisEmulationView.vue'),
        }

    ],

});
